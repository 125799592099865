<template>
  <div v-if="!configLoaded || pgPorcentage < 100">

    <div class="container mt-5"> 

      <div class="row mt-5">
        <div class="col-md-6 offset-md-3 col-sm-12 text-center mt-5 ">
            <div class="card">
                <div class="card-header">
                  <img src="/img/logo-dark.b713141f.png" class="m3 me-2" alt="" height="25">
                </div>
              <div class="card-body">
                <p>Por favor aguarde, carregando Transferporto....</p>
                <img src="https://transferporto.s3.amazonaws.com/cdn/loading.gif" height="120">

                <div class="progress" style="height: 2em;">
                  <div class="progress-bar progress-bar-striped bg-info progress-bar-animated " :style="{ 'width': pgPorcentage + '%' }" >
                    {{ pgPorcentage }}%
                  </div>
                </div>
                <span class="text-muted" v-if="pgPorcentage < 30">Carregando embarques</span>
                <span class="text-muted" v-if="pgPorcentage >= 30 && pgPorcentage <40">Carregando temporadas</span>
                <span class="text-muted" v-if="pgPorcentage >= 40 && pgPorcentage < 43">Carregando navios</span>
                <span class="text-muted" v-if="pgPorcentage >= 43 && pgPorcentage < 50">Carregando cidades</span>
                <span class="text-muted" v-if="pgPorcentage >= 50 && pgPorcentage < 55">Carregando reservas</span>
                <span class="text-muted" v-if="pgPorcentage >= 55 && pgPorcentage < 63">Carregando vouchers</span>
                <span class="text-muted" v-if="pgPorcentage >= 63 && pgPorcentage < 70">Carregando passageiros</span>
                <span class="text-muted" v-if="pgPorcentage >= 70">Finalizando carregamento...</span>
                </div>
                <div class="card-footer p-2">
                  <div class="float-start text-muted"> 
                      <span style="font-size:0.8em">Desenvolvido por</span> 
 <a class="ms-2" href="https://www.favatec.com.br/"><img height="12" src="https://assets.zyrosite.com/cdn-cgi/image/format=auto,w=656,h=116,fit=crop/AwvJwyrx2zsz6qbq/full_trimmed_transparent_customcolor-YZ97vy9lyNiM4MDE.png"></a>
    
                    
                  </div>
                  <div class="float-end text-muted"> 
                     
                      <small>Versão {{ $store.state.session.version }}</small>
                  </div>
                </div>
            </div>
        </div>    
      </div>
    </div>


  </div>
  <router-view v-if="configLoaded && pgPorcentage >= 100 " ></router-view>
</template>

<script>
  export default {
  name: 'App',
  data() {
    return {
          hotLoad : (localStorage.getItem('cache_config2') || window.location.host.includes("localhost") ? true : false),
          pgPorcentage : (window.location.host.includes("localhost") || (localStorage.getItem('cache_config2') ? true : false) ? 110 : 0)
        }
    },    
    computed: {
      configLoaded() {
        return ( this.$store.state.session.loaded  == true);
      }
  },
    watch: {
      configLoaded: function (newv) {
        var _this = this;
        if (newv == true) {
          setTimeout(function () { 
               _this.pgPorcentage = 100;
          }, 100);
       
        }
       }
    },  
  created() {



    console.dir(this.$route);
      var _this = this;
      setInterval(function () {
        if (_this.pgPorcentage <= 101) {
          var random = Math.floor(Math.random() * 7);
          _this.pgPorcentage += random
        }
      },100)
    }
  };

</script>
<style>

.card-radio .form-check-input:checked + .form-check-label {
    border-color: #3590ad !important;
}
.card-radio .form-check-input:checked + .form-check-label:before{
 color: #3590ad !important;
}
.multiselect__option--highlight {
  background: #3590ad !important;
}
.multiselect__tag{
  background-color: #3590ad !important;
  margin-bottom: 0px!important;
  margin-right: 3px!important;
}
.form-check-input:checked {
  background-color: #3590ad !important;
  border-color: #338fac !important;
}

.nav-link:hover,
.nav-link:focus {
  color: #338fac !important;
}

.p-inputtext {
  color: #0f1114 !important;
}

.nav-border-top-primary .nav-link.active {
  border-top-color: #338fac !important;
}

.nav-border-top-primary .nav-link.active {
  color: #338fac !important;
}

.leaflet-control-attribution .leaflet-control {
  display: none !important;
}

.txt-transfer2 {
  color: #338fac !important;
}
.border-radius-0{
  border-radius: 0!important;
}
.btn-transfer-outline {
  color: #338fac !important;

  border-color: #338fac !important;
}

.btn-transfer {
  color: #fff !important;
  background-color: #338fac !important;
  border-color: #338fac !important;
}

.highcharts-credits{
  display: none;
}
.btn-transfer:hover {
  color: #fff !important;
  background-color: #338fac !important;
  border-color: #338fac !important;
}

.btn-check:focus+.btn-transfer,
.btn-transfer:focus {
  color: #fff !important;
  background-color: #338fac !important;
  border-color: #338fac !important;
  box-shadow: 0 0 0 .25rem rgba(49, 132, 253, .5) !important;
}

.btn-check:active+.btn-transfer,
.btn-check:checked+.btn-transfer,
.btn-transfer.active,
.btn-transfer:active,
.show>.btn-transfer.dropdown-toggle {
  color: #fff !important;
  background-color: #338fac !important;
  border-color: #338fac !important;
}

.btn-check:active+.btn-transfer:focus,
.btn-check:checked+.btn-transfer:focus,
.btn-transfer.active:focus,
.btn-transfer:active:focus,
.show>.btn-transfer.dropdown-toggle:focus {
  box-shadow: 0 0 0 .25rem rgba(49, 132, 253, .5) !important;
}
.p-checkbox.p-highlight .p-checkbox-box{
    border-color: #338fac;
    background: #338fac;
}
.p-checkbox:not(.p-disabled):has(.p-checkbox-input:hover) .p-checkbox-box{
    border-color: #338fac;
    background: #338fac;
    color:#fff;
}
.btn-transfer.disabled,
.btn-transfer:disabled {
  color: #fff !important;
  background-color: #338fac !important;
  border-color: #338fac !important;
}

.multiselect__option{
font-weight:120!important;
}

.multiselect__option--selected{
font-weight:bold!important;
}
.btn-excel {
  color: #fff !important;
  background-color: #0e6e38 !important;
  border-color: #0e6e38 !important;
}


.btn-excel:hover {
  color: #fff !important;
  background-color: #0e6e38 !important;
  border-color: #0e6e38 !important;
}



.p-dropdown-items {
  padding-left: 0em;
}

.p-dropdown-label {
  padding: 9px !important;
  font-size: 13px !important;
}

.p-dropdown-filter {
  padding: 5px !important;
  font-size: 10px !important;
}

.p-dropdown-item {
  padding: 0.25rem 1.25rem;
}

.p-dropdown-panel {
  z-index: 2000 !important;
  font-size: 13px;
}

.p-datatable-header {
  background: white;
}

.multiselect-option {
  line-height: 0.5rem !important;
  white-space: nowrap !important;
}

.multiselect-option.is-selected {
  background: #858585 !important;
}

.multiselect__single {
  color: #000 !important;
}
</style>